<template>
  <modal
    transition="nice-modal-fade"
    width="960"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    class="modal--see-promo"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        Promo
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">Promo</div>
        <div class="promo--list">
          <promo-card
            v-for="promo in promoList"
            :promo="promo"
            :key="promo.uuid"
            :isModal="true"
            @choosePromo="choosePromo"
          />
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import PromoCard from '@/components/promo/promo-card';
import { mapState } from 'vuex';
export default {
  components: { PromoCard },
  data() {
    return {
      modalName: 'modal-see-promo',
    };
  },
  computed: {
    ...mapState({
      promoList: (state) => state.promo.promoList,
    }),
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    // eslint-disable-next-line no-unused-vars
    async beforeOpen(event) {
      await this.$store.dispatch('promo/getPromoList');
    },
    choosePromo(promoCode) {
      this.$emit('choosePromo', promoCode);
      this.close();
    },
  },
};
</script>
