<template>
  <modal
    transition="nice-modal-fade"
    width="500"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    class="modal--detail-promo"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <section class="modal--container" v-if="promo">
        <div class="title--text">{{ promo.title }}</div>
        <div class="desc" v-html="promo.terms_conditions"></div>
      </section>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      modalName: 'modal-promo-detail',
      promo: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    // eslint-disable-next-line no-unused-vars
    async beforeOpen(event) {
      this.promo = event.params.promo;
    },
  },
};
</script>
