<template>
  <div class="request--book-promo">
    <div class="left--part">
      <div class="title--text">
        {{ $t('general.promo.title') }}
      </div>
      <div class="see--all" @click="showModal('modal-see-promo')">
        {{ $t('general.promo.check') }}
      </div>
    </div>
    <div class="right--part">
      <input class="basic--input" v-model="promoCode" />
      <div class="error--text" v-show="promoWarning">{{ promoWarning }}</div>
      <div class="mt-4" v-if="chosenPromo">
        <span v-show="!promoWarning">{{ chosenPromo.title }}</span>
        <div class="promo--detail-link" @click="clickPromoDetailHandler">
          {{ $t('general.promo.detail') }}
        </div>
      </div>
    </div>
    <modal-see-promo @choosePromo="choosePromo" />
    <modal-promo-detail />
  </div>
</template>

<script>
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
import ModalSeePromo from '@/components/request-to-book/modals/see-promo';
import ModalPromoDetail from '@/components/request-to-book/modals/promo-detail-modal.vue';

export default {
  name: 'promo-section',
  mixins: [LodashMixin],
  components: {
    ModalSeePromo,
    ModalPromoDetail,
  },
  computed: {
    ...mapState({
      listing: (state) => state.v2.rentTransaction.initiate.listing,
      promoDetail: (state) => state.v2.rentTransaction.initiate.promo.promoDetail,
      chosenPromo: (state) => state.v2.rentTransaction.initiate.promo.chosenPromo,
      promoWarning: (state) => state.v2.rentTransaction.initiate.promo.warningMessage,
      total_rent: (state) => state.v2.rentTransaction.initiate.pricing.totalRent,
    }),
    promoCode: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.promo.promoCode;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/promo/SET_PROMO_CODE', val);
      },
    },
  },
  created() {
    this.debouncedPromo = this.debounce(this.checkPromoCode, 250);
  },
  methods: {
    clickPromoDetailHandler() {
      if (this.promoDetail.banner_uuid) {
        window.open(`/promo/detail/${this.chosenPromo.banner_uuid}`, '_blank');
      } else {
        this.$modal.show('modal-promo-detail', {
          promo: this.promoDetail,
        });
      }
    },
    async checkPromoCode(val) {
      await this.$store.dispatch('v2/rentTransaction/initiate/promo/checkPromo', {
        promoCode: val,
        listingUuid: this.listing.uuid,
        priceBeforePromo: this.total_rent,
      });
      this.$store.commit('v2/rentTransaction/initiate/promo/SET_CHOSEN_PROMO', this.promoDetail);
    },
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    choosePromo(promoCode) {
      this.promoCode = promoCode;
    },
  },
  watch: {
    promoCode(val) {
      this.debouncedPromo(val);
    },
  },
};
</script>

<style scoped></style>
